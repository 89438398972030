.memberCard {
  margin-top: 16px;
  margin-bottom: 16px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.progressBarContainer {
  display: flex;
  margin-top: 15px;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  width: 164px;
  height: 40px;
  background: #333333;
}
.progressBarContainerHidden {
  display: none;
}

.progressBar {
  width: 128px;
  height: 7px;
  background: #616161;
  border-radius: 3px;
}
.progressBarActive {
  border-radius: 3px;
  transition: 3s ease-out;
  background: #d8d8d8;
  height: 7px;
}
.successImage {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 164px;
  height: 40px;
  margin-top: 20px;
}
.successImage > img {
  width: 100px;
  height: 80px;
}

.members-warn {
  strong {
    color: red;
  }
}

.navLinkContainer {
  padding: 5px 0;
}

.navLink {
  width: 343px;
  height: 100px;
  color: rgb(247, 72, 72);
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  letter-spacing: -0.31px;
  line-height: 28px;
}

.navLink:hover {
  color: rgb(247, 72, 72);
}
