// Override Boostrap variables
@import 'bootstrap-variables';
// Import Bootstrap source files from node_modules
@import '~bootstrap/scss/bootstrap';
@import './member/member.scss';

@font-face {
  font-family: 'San Francisco';
  font-weight: 400;
  src: url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff');
}

body {
  background: #ffffff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'San Francisco',
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  line-height: 24px;
}

a {
  color: #e2352a;
}

* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  &:after,
  &::before {
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
}

.app-container {
  box-sizing: border-box;
  .view-container {
    width: 100%;
    max-width: 800px;
    height: calc(100% - 40px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    .card {
      padding: 1rem;
    }
    .view-routes {
      height: 100%;
      > div {
        height: 100%;
      }
    }
  }
}

.fullscreen {
  position: fixed;
  top: 100px;
  left: 0px;
  width: 99% !important;
  height: calc(100vh - 110px) !important;
  margin: 5px;
  z-index: 1000;
  padding: 5px 25px 50px 25px !important;
}

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

/* ==========================================================================
Custom button styles
========================================================================== */

.icon-button > .btn {
  background-color: transparent;
  border-color: transparent;
  padding: 0.5rem;
  line-height: 1rem;
  &:hover {
    background-color: transparent;
    border-color: transparent;
  }
  &:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

/* ==========================================================================
Generic styles
========================================================================== */

/* Temporary workaround for availity-reactstrap-validation */
.invalid-feedback {
  display: inline;
}

/* other generic styles */

.title {
  font-size: 1.25em;
  margin: 1px 10px 1px 10px;
}

.description {
  font-size: 0.9em;
  margin: 1px 10px 1px 10px;
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 2px;
}

.error {
  color: white;
  background-color: red;
}

.break {
  white-space: normal;
  word-break: break-all;
}

.break-word {
  white-space: normal;
  word-break: keep-all;
}

.preserve-space {
  white-space: pre-wrap;
}

/* padding helpers */

@mixin pad($size, $side) {
  @if $size== '' {
    @if $side== '' {
      .pad {
        padding: 10px !important;
      }
    } @else {
      .pad {
        padding-#{$side}: 10px !important;
      }
    }
  } @else {
    @if $side== '' {
      .pad-#{$size} {
        padding: #{$size}px !important;
      }
    } @else {
      .pad-#{$side}-#{$size} {
        padding-#{$side}: #{$size}px !important;
      }
    }
  }
}

@include pad('', '');
@include pad('2', '');
@include pad('3', '');
@include pad('5', '');
@include pad('10', '');
@include pad('20', '');
@include pad('25', '');
@include pad('30', '');
@include pad('50', '');
@include pad('75', '');
@include pad('100', '');
@include pad('4', 'top');
@include pad('5', 'top');
@include pad('10', 'top');
@include pad('20', 'top');
@include pad('25', 'top');
@include pad('30', 'top');
@include pad('50', 'top');
@include pad('75', 'top');
@include pad('100', 'top');
@include pad('4', 'bottom');
@include pad('5', 'bottom');
@include pad('10', 'bottom');
@include pad('20', 'bottom');
@include pad('25', 'bottom');
@include pad('30', 'bottom');
@include pad('50', 'bottom');
@include pad('75', 'bottom');
@include pad('100', 'bottom');
@include pad('5', 'right');
@include pad('10', 'right');
@include pad('20', 'right');
@include pad('25', 'right');
@include pad('30', 'right');
@include pad('50', 'right');
@include pad('75', 'right');
@include pad('100', 'right');
@include pad('5', 'left');
@include pad('10', 'left');
@include pad('20', 'left');
@include pad('25', 'left');
@include pad('30', 'left');
@include pad('50', 'left');
@include pad('75', 'left');
@include pad('100', 'left');

@mixin no-padding($side) {
  @if $side== 'all' {
    .no-padding {
      padding: 0 !important;
    }
  } @else {
    .no-padding-#{$side} {
      padding-#{$side}: 0 !important;
    }
  }
}

@include no-padding('left');
@include no-padding('right');
@include no-padding('top');
@include no-padding('bottom');
@include no-padding('all');

/* end of padding helpers */

.no-margin {
  margin: 0px;
}
@mixin voffset($size) {
  @if $size== '' {
    .voffset {
      margin-top: 2px !important;
    }
  } @else {
    .voffset-#{$size} {
      margin-top: #{$size}px !important;
    }
  }
}

@include voffset('');
@include voffset('5');
@include voffset('10');
@include voffset('15');
@include voffset('30');
@include voffset('40');
@include voffset('60');
@include voffset('80');
@include voffset('100');
@include voffset('150');

.readonly {
  background-color: #eee;
  opacity: 1;
}

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */

a:hover {
  cursor: pointer;
}

.hand {
  cursor: pointer;
}

button.anchor-btn {
  background: none;
  border: none;
  padding: 0;
  align-items: initial;
  text-align: initial;
  width: 100%;
}

a.anchor-btn:hover {
  text-decoration: none;
}

/* ==========================================================================
Metrics and Health styles
========================================================================== */

#threadDump .popover,
#healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px;
}

.thread-dump-modal-lock {
  max-width: 450px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#healthCheck .popover {
  margin-left: -50px;
}

.health-details {
  min-width: 400px;
}

/* bootstrap 3 input-group 100% width
http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */

.width-min {
  width: 1% !important;
}

.form-check-input {
  // margin-top: -0.5rem;
}

.iwocCard {
  //border-bottom: solid 1px red;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 16px;
  padding-bottom: 16px;

  &:not(:first-of-type) {
    // border-top: solid 1px black;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #4a4a4a;
  }
  h1 {
    // font-family: SFProText-Bold;
    font-size: 32px;
    font-weight: bold;
    height: 38px;
    letter-spacing: -1px;
    width: 129px;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.4px;
    line-height: 32px;
  }
}

.iwocInputWrapper {
  border: 1px solid grey;
  border-radius: 4px;
  padding: 4px 12px;
  cursor: pointer;
  transition-duration: 0.3s;

  &:hover {
    color: #fff;
    background-color: #5a6268;
  }
}

.iwocBtnTitle {
  margin: 0px 10px;
}

.iwocAlert {
  border-radius: 4px;
  border-left: 2px solid red;
}

.iwocAlertTitle {
  font-weight: 700;
}

.iwocInput {
  width: 1px;
  color: transparent;
  &::-webkit-file-upload-button {
    visibility: hidden;
  }
}

.iwocContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.iwocPrewContainer {
  width: 100%;
}

.iwocPrew {
  border-radius: 4px;
  height: 93px;
  width: 156px;
  object-fit: contain;
}

.pt-8 {
  padding-top: 8px;
}

.pt-10 {
  padding-top: 10px;
}

.badge {
  font-size: 12px;
  line-height: 18px;
  padding-left: 8px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 12px;
  font-weight: normal;
  border-radius: 4px;
  margin-right: 4px;
}

.btn {
  font-size: 14px;
  padding-left: 16px;
  padding-top: 7.5px;
  padding-bottom: 8.5px;
  background: transparent;
  color: rgb(51, 51, 51);
  border-radius: 4px;
}

.btn-iconic {
  width: 40px;
  height: 40px;
  background: transparent;
  color: black;
  border-radius: 4px;
  border: 1px solid rgb(219, 219, 219);
  padding-left: 0;
  padding-right: 0;
}

.color-red {
  color: red;
}

.modal-content {
  border-radius: 8px;
}

.modal-header {
  border: none;
}

.modal-footer {
  border: none;
  justify-content: flex-start;
}

.form-group {
  label {
    font-weight: bolder;
  }
}

//.padding-16 {
//  padding: 16px;
//}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  //position: relative;
  margin: auto;
  display: inline-block;
  width: 2rem;
  height: 2rem !important;
  overflow: hidden;
  text-indent: -999em;
  vertical-align: text-bottom;
  border: 0.25em solid;
  border-color: currentColor transparent currentColor currentColor;
  border-radius: 50%;
  animation-name: spinner-border;
  animation-duration: 0.75s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.spinner-border-reverse {
  border-color: transparent currentColor transparent transparent;

  &::after {
    position: absolute;
    top: -0.25em;
    right: -0.25em;
    bottom: -0.25em;
    left: -0.25em;
    display: inline-block;
    content: '';
    border: 0.25em solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;

  &.spinner-border-reverse::after {
    border-width: 0.2em;
  }
}

//
// Growing circle
//

@keyframes spinner-grow {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.spinner-container {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.spinner-grow {
  margin: auto;
  display: inline-block;
  width: 2rem;
  height: 2rem !important;
  overflow: hidden;
  text-indent: -999em;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  animation-name: spinner-grow;
  animation-duration: 0.75s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
